"use client"
import React from 'react';
import { Loader } from "@mantine/core";

interface LoadingProps {
    title?: string
}

export default function Loading({ title }:LoadingProps){
    return (
        <div style={{flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh', flexDirection: 'column'}}>
            <Loader size={64} />
            <p>{title}</p>
        </div>
    )
}