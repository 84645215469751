 "use client"
import { Container, Title, Text, LoadingOverlay, Paper, TextInput, PasswordInput, Button, Notification, rem, Alert, Stack, Group, Anchor } from "@mantine/core";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "@mantine/form";
import { IconExclamationMark, IconX } from "@tabler/icons-react";
import { useRouter, useSearchParams } from "next/navigation";
import type { SignInResource, ClerkAPIError } from "@clerk/types";

import { useSession, useSignIn } from '@clerk/nextjs';
import Loading from "../../../../components/Loading";
import { notifications } from "@mantine/notifications";

interface LoginFormValues {
  email: string;
  password?: string;
}

export default function LoginPage({}) {

  const { isLoaded, signIn, setActive } = useSignIn();
  const { isSignedIn, session} = useSession();

  const params = useSearchParams()

  const [token, setToken] = useState(params.get('__clerk_ticket'))
  const [isAuthenticating, setIsAuthenticating] = useState(false)
  const [isAuthenticatingUsingToken, setIsAuthenticatingUsingToken] = useState(false)

  const [buttonLoading, setButtonLoading] = useState(false)

  const [passwordStrategySupported, setPasswordStrategySupported] = useState(false)
  const [emailLinkStrategySupported, setEmailLinkStrategySupported] = useState(false)
  const [resetPasswordStrategySupported, setResetPasswordStrategySupported] = useState(false)

  const [signInResult, setSignInResult] = useState<SignInResource | null>(null)
  const [emailId, setEmailId] = useState<string | null>(null)

  const [emailLinkSent, setEmailLinkSent] = useState(false);
  const [autoEmailLinkFlow, setAutoEmailLinkFlow] = useState(false);

  const router = useRouter()

  const form = useForm<LoginFormValues>({
    initialValues: {
      email: '',
      password: '',
    },
    initialErrors: {
      generalError: false
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
    // transformValues: (values) => ({
    //   email: values.email.toLowerCase(),
    // }),
  });

  const onSubmit = async (values:LoginFormValues) => {
    if(signIn){
      setButtonLoading(true)
      const email = values.email.toLowerCase()
      form.setFieldValue('email', email)
      try {
        if(values.password){
          // Password flow
          const result = await signIn.create({
            identifier: email,
            password: values.password
          });
          await processSignInResult(result)
        } else {          
          const result = await signIn.create({ identifier: email });
          setSignInResult(result)

          result.supportedFirstFactors.forEach( ff => {
            if(ff.strategy === "email_link" && ff.safeIdentifier === email){
              setEmailLinkStrategySupported(true)
              setEmailId(ff.emailAddressId)
              if(!result.supportedFirstFactors.find(sff => sff.strategy === "password")){
                setAutoEmailLinkFlow(true)
              }
            } else if(ff.strategy === "password") {
              setPasswordStrategySupported(true)
            } else if(ff.strategy === "reset_password_email_code" && ff.safeIdentifier === email){
              setResetPasswordStrategySupported(true)
            }
          })

          setButtonLoading(false)
        }
  
      } catch (err: any) {
        const errors = err.errors as ClerkAPIError[];
        console.error("errors", errors)
        form.setErrors({ generalError: errors[0].longMessage || errors[0].message });
        setIsAuthenticating(false)
        setButtonLoading(false)
      }
    }
  }

  const emailLinkFlow = useCallback( () => {
    if(!signInResult || !emailId) return;

    const { startEmailLinkFlow, cancelEmailLinkFlow } = signInResult.createEmailLinkFlow();
    
    startEmailLinkFlow({
      emailAddressId: emailId,
      redirectUrl: (process.env.NEXT_PUBLIC_CLERK_BASE_URL || '') + '/auth/verification'
    }).then(emailLinkFlowResult => {
      const verification = emailLinkFlowResult.firstFactorVerification;
      processSignInResult(signInResult)
    })
    setEmailLinkSent(true)
    setButtonLoading(true)
    
  },[signInResult, emailId])

  useEffect(() => {
    if(autoEmailLinkFlow){
      emailLinkFlow()
    }
  }, [autoEmailLinkFlow, emailLinkFlow])

  const resetPassword = async () => {
    if(!signIn) return 

    try {
      await signIn.create({
        strategy: 'reset_password_email_code',
        identifier: form.values.email,
      })
      router.push('/auth/reset-password?email='+form.values.email)
    } catch (err: any) {
      const errors = err.errors as ClerkAPIError[];
      console.error("errors", errors)
      form.setErrors({ generalError: errors[0].longMessage || errors[0].message });
      notifications.show({
        color: 'red',
        message: errors[0].longMessage || errors[0].message,
      })
      setIsAuthenticating(false)
      setButtonLoading(false) 
    }
  }

  const processSignInResult = useCallback(async (result: SignInResource) => {
    if (result.status === "complete" && setActive) {
      setActive({
        session: result.createdSessionId,
        beforeEmit: () => router.push("/")
      });
    }
    else {
      /*Investigate why the login hasn't completed */
      setToken(null)
      setIsAuthenticating(false)
      setIsAuthenticatingUsingToken(false)
    }
  },[setActive])

  // SignInByToken effect
  useEffect(() => {
    if(token && !isSignedIn && !isAuthenticatingUsingToken){
      signInByToken(token)
    }
  },[signIn, isLoaded, token, isAuthenticatingUsingToken, isSignedIn])

  const signInByToken = useCallback(async (token: string) => {
    if (!signIn || isAuthenticatingUsingToken) {
      return;
    }
    try {
      setIsAuthenticatingUsingToken(true)
      const result = await signIn.create({
        strategy: "ticket",
        ticket: token as string,
      });
      await processSignInResult(result)
    } catch (err: any) {
      const errors = err.errors as ClerkAPIError[];
      console.error("errors", errors)
      notifications.show({
        color: 'red',
        message: errors[0].longMessage || errors[0].message,
      })
      setToken(null)
      setIsAuthenticatingUsingToken(false)
    }
  },[signIn, isAuthenticatingUsingToken, processSignInResult])

  useEffect(() => {
    if(isSignedIn){
      router.push('/')
    }
  },[isSignedIn])

  // Rendering

  if(!isLoaded){
    return <Loading title="Loading..."/>
  }

  if(isAuthenticatingUsingToken){
    return <Loading title="Authenticating..."/>
  }

  return (
    <Container size={420} my={40}>
      <Title ta="center">
        Welcome back!
      </Title>
      <Paper withBorder shadow="md" p={30}radius="md">
        <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
          <LoadingOverlay visible={isAuthenticating} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
          <Stack>
            {form.errors.generalError && (
              <Notification icon={<IconX style={{ width: rem(20), height: rem(20) }} />} color="red" title={form.errors.generalError} onClose={() => form.clearFieldError('generalError')}>
                {/* {form.errors.generalError} */}
              </Notification>
            )}
            <TextInput
              label="Email"
              placeholder="you@email.com"
              required
              readOnly={buttonLoading || !!emailId}
              {...form.getInputProps('email')}
            />
            {(passwordStrategySupported && !emailLinkSent) && (
              <PasswordInput
                label="Password"
                placeholder="Your password"
                required
                readOnly={buttonLoading}
                {...form.getInputProps('password')}
              />
            )}

            {emailLinkSent && (
              <Alert variant="light" color='green' title="Email sent!">
                We send you email with magic link to sign in!<br/>
                Open your email app and click a link from any device you want, then back here.
              </Alert>
            )}
            
            <Button fullWidth type="submit" loading={buttonLoading}>Sign in</Button>

            {(!buttonLoading && !emailLinkSent) && (
              <Group justify='space-between'>
                {resetPasswordStrategySupported && <Anchor size="sm" onClick={() => resetPassword()}>Forget password</Anchor>}
                {emailLinkStrategySupported && <Anchor size="sm" onClick={() => emailLinkFlow() }>Sign in using magic link</Anchor>}
              </Group>
            )}
          </Stack>
        </form>
      </Paper>
    </Container>
  )
}
